// FULL SIZE NAVIGATION

function getElements(className) {
  return document.getElementsByClassName(className)
}

function toggleNavigationContainer(event) {
  $(event.target)
    .closest('.navigation-top-level-container-outer')
    .addClass('navigation-top-level-container-outer-active')
}

function toggleDropdown(event) {
  var position = $(event.target)
    .closest('.navigation-top-level-container-outer')[0].dataset.position
  $('.navigation-top-level-dropdown-container-outer-' + position).show()
  console.log(position)
}

function setAllInactive() {
  Array.prototype.map.call(getElements('navigation-top-level-container-outer'), function(element) {
    element.className = 'navigation-top-level-container-outer'
  })
}

function hideAllDropdowns() {
  $('.navigation-top-level-dropdown-container-outer').hide()
}

function setActive(event) {
  event.stopPropagation()
  if (
    $(event.target)
      .closest('.navigation-top-level-container-outer')
      .hasClass('navigation-top-level-container-outer-active')
  ) {
    setAllInactive()
    hideAllDropdowns()
  } else {
    setAllInactive()
    hideAllDropdowns()
    toggleNavigationContainer(event)
    toggleDropdown(event)
  }
}

// MOBILE / HAMBURGER

function toggleHamburger() {
  $('.hamburger').toggleClass('is-active')
  $('.navigation-hamburger-container-outer').toggle()
}

function toggleHamburgerLevel1(event) {
  var closest = $(event.target)
    .closest('.level-1-toggle')
  if (closest.hasClass('level-1-toggle-active')) {
    $('.level-1-toggle-active').toggleClass('level-1-toggle-active')
    $('.level-1-expand').hide()
  } else {
    $('.level-1-toggle-active').toggleClass('level-1-toggle-active')
    closest.toggleClass('level-1-toggle-active')
    $('.level-1-expand').hide()
    $('.level-1-expand-' + closest[0].dataset.position).show()
  }

}

$(function() {
  $('.navigation-top-level-container-outer-dropdown-true').click(function(event) {
    setActive(event)
  })
})
